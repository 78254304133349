

import SaveButton from "../save-button";

export default {
    name: "CompanyFeedbackModal",
    components: {
        SaveButton,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "companyFeedbackModal",
        },
        event: {
            type: Object,
            required: true,
        },
        feedbackCell: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            cellWorkingCopy: null,
            saving: false,
            aiGenerating: false,
            newFeedbackNotes: 0,
        }
    },
    watch: {
        feedbackCell: {
            handler(newValue) {
                this.resetWorkingCopy();
            },
            deep: true,
            immediate: true,
        },
        event: {
            handler() {
                this.resetWorkingCopy();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.resetWorkingCopy();
    },
    methods: {
        formSubmitHandler() {
            this.saving = true;
            this.$axios.patch('/api/event_has_companies/' + this.cellWorkingCopy.eventHasCompanyId, {
                feedback: this.cellWorkingCopy.feedback,
                aiUsedNotesIds: this.cellWorkingCopy.aiUsedNotesIds,
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            }).then((response) => {
                this.saving = false;
                this.$emit('feedback-updated', response.data);
            });
        },
        resetWorkingCopy() {
            if (this.feedbackCell === null) {
                this.cellWorkingCopy = null;
            } else {
                this.cellWorkingCopy = structuredClone(this.feedbackCell);
                this.loadNewFeedbackNotes();
            }
        },
        loadNewFeedbackNotes() {
            this.$axios.get('/api/events/has_new_notes/' + this.cellWorkingCopy.eventHasCompanyId).then((response) => {
                this.newFeedbackNotes = response.data;
            });
        },
        generate(modeGen) {
            this.aiGenerating = true;
            const params = {
                companyId: this.feedbackCell.companyId,
                eventId: this.event.id,
                mode: modeGen ? "generate" : "merge",
            };
            this.$axios.post('/api/events/feedback_summary?' + this.buildQueryString(params), {
                feedback: modeGen ? "" : this.cellWorkingCopy.feedback,
            }).then((response) => {
                this.cellWorkingCopy.feedback = response.data.response;
                this.cellWorkingCopy.aiUsedNotesIds = response.data.notes;
            }).finally(() => {
                this.newFeedbackNotes = 0;
                this.aiGenerating = false;
            });
        },
    }
}
