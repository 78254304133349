import { render, staticRenderFns } from "./events-table.vue?vue&type=template&id=63a5a80e&scoped=true"
import script from "./events-table.vue?vue&type=script&lang=js"
export * from "./events-table.vue?vue&type=script&lang=js"
import style0 from "./events-table.vue?vue&type=style&index=0&id=63a5a80e&prod&scoped=true&lang=css"
import style1 from "./events-table.vue?vue&type=style&index=1&id=63a5a80e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63a5a80e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src3271430876/src/web/components/table-filters.vue').default,TableActions: require('/codebuild/output/src3271430876/src/web/components/table-actions.vue').default,GmapsAddress: require('/codebuild/output/src3271430876/src/web/components/gmaps-address.vue').default,ConfirmModal: require('/codebuild/output/src3271430876/src/web/components/confirm-modal.vue').default,DownloadSelectColumnsModal: require('/codebuild/output/src3271430876/src/web/components/download-select-columns-modal.vue').default})
