
import {mapGetters} from "vuex";
import ConfirmModal from "../confirm-modal";
import TableActions from "../table-actions";
import ContactExcelImport from "../contacts/contact-excel-import";
import SelectContacts from "../contacts/select-contacts";
import modals from "../../mixins/modals";
import LoadingSpinner from "../loading-spinner";
import EditBaseGroupFields from "../groups/edit-base-group-fields";
import iriPreparation from "../../mixins/iri-preparation";
import contactList from "../../mixins/contact-list";
import ContactName from "../contacts/contact-name.vue";
import SaveButton from "@/components/save-button";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "EditContactListModal",
    components: {
        ContactName,
        EditBaseGroupFields,
        LoadingSpinner,
        SelectContacts,
        ContactExcelImport,
        // SearchSelect,
        TableActions,
        ConfirmModal,
        SaveButton
    },
    mixins: [optionButtonTooltip, modals, iriPreparation, contactList],
    props: {
        contactListId: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            isTemporary: null,
            contactsUpdated: new Date(),
            contactListWorkingCopy: null,
            saving: false,
            saved: false,
            collapseShown: false,
            contactToAdd: null,
            contactSaving: false,
            rowToRemove: null,
            confirmOpen: false,
            emailError: null,
            // newContacts: [],
            newEmailAddresses: "",
            emailAddressValidation: false,
            handleFile: false,
            selectedContactIds: [],
            originalContacts: [],
            modifications: {
                added: {},
                removed: {},
            },
            initialLoad: true,
            ajaxAll: 0,
            ajaxRows: null,
            pageChanged: false,
            preSelectedRows: [],
            preSelectedRowIds: {},
            preSelectedRowIdsCount: 0,
            selectedRows: [],
            searchQuery: "",
            multipleContactsToRemove: null,
            loading: false,
            duplicateGroups: null,
            config: {
                search: true,
                pagination: 10,
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                pageSortSelect: true,
                columns: [
                    {
                        headline: "E-Mail",
                        sort: true,
                    },
                    {
                        headline: "Name",
                        sort: true,
                    },
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Role/Title",
                        breakpoint: "all",
                    },
                    {
                        headline: "Department",
                        breakpoint: "all",
                    },
                    {
                        headline: "Country",
                        sort: true,
                    },
                    {
                        headline: "Interactions",
                        sort: true,
                        breakpoint: "all",
                        hidden: true,
                    },
                    {
                        headline: "Last Interaction",
                        sort: false,
                        breakpoint: "all",
                        hidden: true,
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ]
            },
            withoutGroup: false,
        }
    },

    computed: {
        ...mapGetters({
            currentRoute: "app/currentRoute"
        }),

        contactParams() {
            const params = {};
            if (this.withoutGroup) {
                params.without_group = true
            } else if (this.contactListWorkingCopy && this.contactListWorkingCopy.id) {
                params.exclude_groups = this.contactListWorkingCopy.id
            }
            params.onlyEmail = false;
            params.hideExcluded = false;
            return params;
        },

        isNew() {
            return !this.contactListWorkingCopy || !this.contactListWorkingCopy.id
        },
        milliseconds() {
            return this.contactsUpdated.getUTCMilliseconds();
        },
        /* preSelectedRowIdsCount() { // RJA Calculating this in computed did not work.
            console.log("Keys", Object.keys(this.preSelectedRowIds));
            return Object.keys(this.preSelectedRowIds).length;
        }, */
        contactsManipulated() {
            return (Object.keys(this.modifications.added).length > 0 || Object.keys(this.modifications.removed).length > 0);
        },
        finalContactListId() {
            return this.contactListWorkingCopy && this.contactListWorkingCopy.id ? this.contactListWorkingCopy.id : this.contactListId;
        }
    },
    watch: {
        newEmailAddresses() {
            this.emailAddressValidation = false;
        },
        // contactList:{
        //     handler(val){
        //         this.contactListWorkingCopy = JSON.parse(JSON.stringify(val));
        //         this.saved = false;
        //     },
        //     immediate:true
        // },
        contactToAdd(val) {
            if (val) {
                this.emailError = false;

                if (!val['@id'] && !this.isEmailAddress(val.nameWithCompany)) {
                    this.emailError = true;
                    this.$formulate.submit("add-contact-form");
                    return;
                }

                this.contactSaving = true;

                this.$axios.post('/api/contact_list_contacts', {
                    contact: val['@id'] ? val['@id'] : {
                        email: val.nameWithCompany
                    },
                    contactList: `/api/contact_lists/${this.contactListWorkingCopy.id}`
                }).then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Added",
                        message: "Contact added to group",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                    this.contactToAdd = null;
                    this.contactsUpdated = new Date();
                    this.emailError = null;
                    this.modifications.added[this.extractNumericId(response.data.contact)] = true;
                    this.loadContactList();
                }).finally(() => {
                    this.contactSaving = false;
                });
            } else {
                this.emailError = false;
                this.$formulate.reset("add-contact-form")
            }
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                // rows are all currently checked rows - make sure they are in preSelectedRows
                for (const row of rows) {
                    this.preSelectedRowIds[row.clc_id] = {
                        clc_id: row.clc_id,
                        contact_id: row.contact_id,
                    };
                    // console.log("Adding CLC " + row.clc_id, this.preSelectedRowIds);
                }

                const rowsIds = new Set(rows.map(row => row.clc_id)); // Currently checked CLC IDs
                for (const row of this.ajaxRows) { // Go over all rows in table (ajax result!)
                    const isNotChecked = !rowsIds.has(row.clc_id);
                    const wasPreviouslyChecked = this.preSelectedRowIds[row.clc_id] !== undefined;
                    if (isNotChecked && wasPreviouslyChecked) {
                        delete this.preSelectedRowIds[row.clc_id]; // uncheck it!
                        // console.log("Removing CLC " + row.clc_id, this.preSelectedRowIds);
                    }
                }

                this.preSelectedRowIdsCount = Object.keys(this.preSelectedRowIds).length;
            }
        },
        isNew: {
            handler(bool) {
                if (!bool) {
                    this.$set(this.config, "ajaxUrl", `/api/emails/contact_list/${this.contactListWorkingCopy.id}/contacts`)
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.$refs.editContactListModal.addEventListener('hidden.bs.modal', this.onHideModal)

        this.$refs.editContactListModal.addEventListener('shown.bs.modal', this.onShowModal)

        this.$refs.removeContactFromListModal.$el.addEventListener('show.bs.modal', () => {
            this.$refs.editContactListModal.removeEventListener('hidden.bs.modal', this.onHideModal)
            this.$refs.editContactListModal.removeEventListener('shown.bs.modal', this.onShowModal)
            this.confirmOpen = true;
        })
        this.$refs.removeContactFromListModal.$el.addEventListener('hide.bs.modal', () => {
            this.confirmOpen = false;
        })
        this.$refs.removeContactFromListModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.editContactListModal.addEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editContactListModal.addEventListener('shown.bs.modal', this.onShowModal)
            })
        })

        this.$refs.removeMultipleContacteFromListModal.$el.addEventListener('show.bs.modal', () => {
            this.$refs.editContactListModal.removeEventListener('hidden.bs.modal', this.onHideModal)
            this.$refs.editContactListModal.removeEventListener('shown.bs.modal', this.onShowModal)
            this.confirmOpen = true;
        })
        this.$refs.removeMultipleContacteFromListModal.$el.addEventListener('hide.bs.modal', () => {
            this.confirmOpen = false;
        })
        this.$refs.removeMultipleContacteFromListModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.editContactListModal.addEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editContactListModal.addEventListener('shown.bs.modal', this.onShowModal)
            })
        })

        this.$refs.deleteContactListModal.$el.addEventListener('show.bs.modal', () => {
            this.$refs.editContactListModal.removeEventListener('hidden.bs.modal', this.onHideModal)
            this.$refs.editContactListModal.removeEventListener('shown.bs.modal', this.onShowModal)
            this.confirmOpen = true;
        })
        this.$refs.deleteContactListModal.$el.addEventListener('hide.bs.modal', () => {
            this.confirmOpen = false;
        })
        this.$refs.deleteContactListModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.editContactListModal.addEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editContactListModal.addEventListener('shown.bs.modal', this.onShowModal)
            })
        })

        this.$refs.collapse.addEventListener('show.bs.collapse', () => {
            this.collapseShown = true;
        })
        this.$refs.collapse.addEventListener('hide.bs.collapse', () => {
            this.collapseShown = false;
        })
    },
    methods: {
        onFileUploaded(val) {
            this.handleFile = !!val;
        },
        onShowModal() {
            this.updateDuplicateWarning();
            if (this.contactListId) {
                this.loadContactList();
            } else {
                this.contactListWorkingCopy = {
                    title: "",
                    description: "",
                    isPrivate: true,
                    creatingUser: `/api/users/${this.userInfo.id}`,
                    isTemporary: false,
                    sharedWithUsers: []
                }
            }
        },
        onHideModal() {
            if (this.saved) {
                this.$emit('contact-list-updated')
            } else if (this.contactsManipulated) {
                this.$emit('contact-list-updated')
                // this.revert(this.contactListWorkingCopy.id);
            }

            this.contactListWorkingCopy = null;
            this.saved = false;
            this.initialLoad = true;
        },
        onRowsUpdated(data) {
            this.ajaxRows = data.rows;
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {

                    this.preSelectedRows = Object.values(this.preSelectedRowIds).map(row => {
                        return {
                            key: "clc_id",
                            value: row.clc_id,
                        }
                    });

                });
            }
            this.ajaxAll = data.all;
        },
        removeContact(cell) {
            this.rowToRemove = {
                clcId: cell.clc_id,
                contactId: cell.contact_id,
            };
            this.modal = this.openModal(this.$refs.removeContactFromListModal);
        },
        doRemoveContact({clcId, contactId}) {
            this.loading = true;

            this.$axios.delete("/api/contact_list_contacts/" + clcId).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Contact removed from group",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.loadContactList();
                })
                this.modifications.removed[contactId] = true;
            }).finally(() => {
                this.contactsUpdated = new Date()
                this.rowToRemove = null;
                this.loading = false;
            });
            this.doSave(true)
        },
        cancelRemoveContact() {
            this.rowToRemove = null
        },
        loadContactList() {
            this.$axios.get(`/api/contact_lists/${this.finalContactListId}`).then((response) => {
                this.contactListWorkingCopy = structuredClone(response.data);
                if (this.initialLoad) {
                    this.initialLoad = false;
                }
            })
        },
        save() {
            this.emailAddressValidation = false;
            this.newEmailAddresses.trim().split(",").forEach((ea) => {
                if (ea.trim() && !this.isEmailAddress(ea.trim())) {
                    this.emailAddressValidation = true;
                }
            })

            this.$nextTick(() => {
                this.$formulate.submit("contact-list-form");
            })

        },
        /*
        DEACTIVATED AS OF 2024-05-28
        revert(id) {
            this.saving = true;

            this.$axios.post('/api/contact_lists/revert_contacts_modification', {
                contactListId: this.contactListWorkingCopy.id,
                added: Object.keys(this.modifications.added),
                removed: Object.keys(this.modifications.removed),
            }).then(() => {
                this.modifications = {
                    added: {},
                    removed: {},
                };
            }).finally(() => {
                this.saving = false;
            })
        },
         */
        doSave(doEmitSavedEvent) {
            this.saving = true;

            // Prepare the contactList object
            this.contactListWorkingCopy = this.prepareIri(this.contactListWorkingCopy);

            // Prepare the sharedWithUsers => IRIs only!
            if (this.contactListWorkingCopy.sharedWithUsers) {
                this.contactListWorkingCopy.sharedWithUsers = this.prepareIri(this.contactListWorkingCopy.sharedWithUsers);
            }

            if (this.isNew) {
                // Creating a new Contact List => POST

                const contacts = [];
                // this.newContacts.forEach((c)=>{
                //     contacts.push({
                //         contact:c["@id"]
                //     })
                // })

                this.selectedContactIds.forEach((id) => {
                    contacts.push({
                        contact: `/api/contacts/${id}`
                    })
                })
                this.newEmailAddresses.trim().split(",").forEach((ea) => {
                    if (ea.trim()) {
                        contacts.push({
                            contact: {
                                email: ea.trim()
                            }
                        })
                    }
                })

                let promise = Promise.resolve(null);

                if (this.handleFile) {
                    promise = this.$refs.importForm.submitForm();
                }

                promise.catch(() => {
                    // TODO is this intentional that we set saving to false but still save?
                    this.saving = false;
                })

                promise.then((importedContacts) => {

                    if (importedContacts) {
                        importedContacts.forEach((c) => {
                            contacts.push({
                                contact: `/api/contacts/${c.id}`,
                            })
                        })
                    }

                    this.contactListWorkingCopy.contactListContacts = contacts;
                    this.$axios.post("/api/contact_lists", this.contactListWorkingCopy).then((response) => {
                        this.contactListWorkingCopy = JSON.parse(JSON.stringify(response.data));
                        this.selectedContactIds = [];

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Saved",
                            message: "Group added",
                            id: toastId,
                        })
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.saving = false;
                        this.saved = true;
                        this.emailAddressValidation = false;
                    })
                })
            } else {
                // Edit an existing list => PATCH

                // Delete stuff what we do not want to patch
                this.$delete(this.contactListWorkingCopy, "contactListHistories");
                this.$delete(this.contactListWorkingCopy, "emailRecipients");
                console.log(this.contactListWorkingCopy)
                // Send the request
                this.$axios.patch(`/api/contact_lists/${this.contactListWorkingCopy.id}`, this.contactListWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.contactListWorkingCopy = JSON.parse(JSON.stringify(response.data));
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Saved",
                        message: "Group updated",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })

                    if (!doEmitSavedEvent) {
                        this.$emit("saved");
                    }
                }).finally(() => {
                    this.saving = false;
                    this.saved = true;
                })
            }
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowsIds = {};
        },
        removeMultipleContacts(mode) {
            this.multipleContactsToRemove = mode;
            this.modal = this.openModal(this.$refs.removeMultipleContacteFromListModal);

        },
        doRemoveMultipleContacts(mode) {
            this.loading = true;

            let promise;

            if (mode === "selected" || mode === "page") {
                let idsToRemove;
                switch (mode) {
                    case "selected":
                        idsToRemove = Object.values(this.preSelectedRowIds).map(row => row.contact_id);
                        break;

                    case "page":
                        idsToRemove = this.ajaxRows.map(row => row.contact_id);
                        break;
                }

                promise = this.removeContactsFromList(this.contactListWorkingCopy.id, idsToRemove).then(() => {
                }).then((response) => {
                    for (const contactId of idsToRemove) {
                        this.modifications.removed[contactId] = true;
                    }
                });

            } else if (mode === "all") {
                // Removes all Contacts from a Contact List
                promise = this.$axios.post(`/api/contact_lists/remove_all_contacts`, {
                    contactListId: this.contactListWorkingCopy.id,
                }).then((response) => {
                    for (const contactId of response) {
                        this.modifications.removed[contactId] = true;
                    }
                });
            }

            promise.then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Contacts removed from group",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.loadContactList();
                })
            }).finally(() => {
                this.contactsUpdated = new Date()
                this.multipleContactsToRemove = null;
                this.loading = false;
            });

            this.doSave(true);
        },
        cancelRemoveMultipleContacts() {
            this.multipleContactsToRemove = null;
        },
        updateSearch(query) {
            this.searchQuery = query;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        deleteContactGroup() {
            this.modal = this.openModal(this.$refs.deleteContactListModal);
        },
        doDeleteContactGroup(id) {
            this.contactListWorkingCopy = null
            this.$emit("delete", id)
        },
        updateDuplicateWarning(val) {
            if (!this.isNew || String(val).length < 3) {
                this.duplicateGroups = [];
                return;
            }
            this.$axios.get(`/api/emails/contact_lists?search=${val}`)
                .then((response) => {
                    if (response.data && response.data.all > 0) {
                        this.duplicateGroups = response.data.rows;
                    } else {
                        this.duplicateGroups = null;
                    }
                });
        },
    },
}
