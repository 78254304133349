
import moment from "moment";
import HintText from "../hint-text.vue";
import interactionConflicts from "../../mixins/interaction-conflicts";
import ConflictsWarning from "../interactions/conflicts-warning.vue";

export default {
    name: "EventMeeting",
    components: {ConflictsWarning, HintText},
    mixins: [interactionConflicts],
    props: {
        value: {
            type: Object,
            required: true,
        },
        interactionTypes: {
            type: Array,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            selectedContacts: [],
            highlightFieldStart: false,
            highlightFieldEnd: false,
            isBlocker: false,
        }
    },
    computed: {
        event() {
            return this.value.event
        },
        start: {
            get() {
                if (this.value.start) {
                    return moment.tz(this.value.start, this.timezone).locale('en-US').format("YYYY-MM-DD HH:mm");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$emit("input", {...this.value, start: newValue});

                if (newValue) {
                    this.$nextTick(() => {
                        if (!this.end || moment.tz(newValue, this.timezone).unix() > moment.tz(this.end, this.timezone).unix() || this.end === "Invalid date") {
                            // Either end is missing or new start is after current end
                            this.end = moment(newValue).add(30, 'm').format('YYYY-MM-DD HH:mm');
                            this.highlightChangedFieldEnd();
                        }
                    });
                }
            }
        },
        end: {
            get() {
                if (this.value.end) {
                    return moment.tz(this.value.end, this.timezone).locale('en-US').format("YYYY-MM-DD HH:mm");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$emit("input", {...this.value, end: newValue});

                if (newValue) {
                    this.$nextTick(() => {
                        if (!this.start || moment.tz(newValue, this.timezone).unix() < moment.tz(this.start, this.timezone).unix()) {
                            // Either start is missing or new end is before current start
                            this.start = moment(newValue).subtract(30, 'm').format('YYYY-MM-DD HH:mm');
                            this.highlightChangedFieldStart();
                        }
                    });
                }
            }
        },
        interactionType: {
            get() {
                return this.value.interactionType;
            },
            set(newValue) {
                console.log("New Value", newValue);
                this.$emit("input", {...this.value, interactionType: newValue});
            }
        },
        subject: {
            get() {
                return this.value.subject;
            },
            set(newValue) {
                this.$emit("input", {...this.value, subject: newValue});
            }
        },
        meetingPoint: {
            get() {
                return this.value.meetingPoint;
            },
            set(newValue) {
                this.$emit("input", {...this.value, meetingPoint: newValue});
            }
        },
        timezone: {
            get() {
                return this.value.timezone;
            },
        },
    },
    watch: {
        selectedContacts: {
            handler() {
                this.$emit("input", {...this.value, selectedContacts: this.selectedContacts});
            },
        },
        interactionType: {
            handler() {
                if (typeof this.interactionType === "string") {
                    const interactionType = this.interactionTypes.find(x => x['@id'] === this.interactionType)
                    this.isBlocker = interactionType.title === "Blocker";
                    console.log("Is blocker", this.isBlocker);
                }
            },
            deep: true,
        }
    },
    created() {
        if (this.value.selectedContacts && this.value.selectedContacts.length > 0) {
            this.selectedContacts = this.value.selectedContacts;
        } else if (this.value.interactionParticipants && this.value.interactionParticipants.length > 0) {
            this.selectedContacts = this.value.interactionParticipants.map(
                interactionParticipant => interactionParticipant.contact
            );
        } else {
            this.selectedContacts = [];
        }
    },
    methods: {
        timeZoneAbbr(start, timezone) {
            // eslint-disable-next-line new-cap
            const abbr = new moment.tz(start, timezone).zoneAbbr();
            return abbr;
        },
        async highlightChangedFieldStart() {
            this.highlightFieldStart = true;
            // Adjust the timeout duration (2000ms = 2 seconds) to control how long the highlight lasts.
            await new Promise((resolve) => setTimeout(resolve, 5000));
            this.highlightFieldStart = false;
        },
        async highlightChangedFieldEnd() {
            this.highlightFieldEnd = true;
            // Adjust the timeout duration (2000ms = 2 seconds) to control how long the highlight lasts.
            await new Promise((resolve) => setTimeout(resolve, 5000));
            this.highlightFieldEnd = false;
        },
    },
}
